<script setup lang="ts">
import { ref, computed } from 'vue'

import { TemperIcon } from '@temperworks/icons'
import {
  ImageTypes,
  TypographyType,
  ModalVariant,
  DimmingOverlayVariant,
  IconNames,
  IconColors,
  IconPosition,
  IconSizes
} from '@temperworks/types'

import {
  Typography,
  DimmingOverlay,
} from '@temperworks/components'

interface Props {
  open: boolean
  variant: ModalVariant
  modalHeader?: string
  image?: ImageTypes
  showOverlay?: boolean
  scroll?: boolean
  closeOption?: boolean
  backOption?: boolean
  fluid?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  scroll: true,
  modalHeader: undefined,
  image: undefined
})

const emit = defineEmits(['close'])
const closeButton = ref(null)
const modalHeaderElement = ref(null)
const modalFooterElement = ref(null)
const modalContentElement = ref(null)

function close() {
  if (props.closeOption || props.backOption) {
    emit('close')
  }
}

const getModalHeight = computed(() => {
  const contentBoundingRect = modalContentElement.value?.getBoundingClientRect()
  const headerBoundingRect = modalHeaderElement.value?.getBoundingClientRect()
  const footerBoundingRect = modalFooterElement.value?.getBoundingClientRect()

  return contentBoundingRect?.height + headerBoundingRect?.height + footerBoundingRect?.height
})

const modalHeight = computed(() => {
  return {
    height: getModalHeight.value ? `${getModalHeight.value}px` : 'unset',
  }
})
</script>

<template>
  <div
    v-if="props.open"
    :class="[{ 'fluid': props.fluid }, props.variant]"
    :style="modalHeight"
    @keydown.esc="close"
  >
    <div class="modal-inner">
      <div
        ref="modalHeaderElement"
        v-if="props.modalHeader"
        class="modal-header"
      >
        <TemperIcon
          v-if="props.backOption"
          class="back-icon"
          @click="close()"
          :name="IconNames.arrowLeft"
          :size="IconSizes.medium"
          :position="IconPosition.center"
          :color="IconColors.purple500"
        />
        <div class="modal-title">
          <Typography
            :content="props.modalHeader"
            :variant="TypographyType.bodyMedium"
          />
        </div>
        <TemperIcon
          v-if="props.closeOption"
          ref="closeButton"
          class="close-icon"
          @click="close()"
          :name="IconNames.close"
          :size="IconSizes.medium"
          :position="IconPosition.center"
          :color="IconColors.purple500"
        />
      </div>
      <div
        v-if="props.variant.includes('image')"
      >
        <div class="modal-visual">
          <picture>
            <source
              media="max-width:992px"
              :srcset="props.image.mobile"
            >
            <img :src="props.image.desktop">
          </picture>
        </div>
      </div>
      <div
        ref="modalContentElement"
        class="modal-body"
        :class="[{ 'scroll': props.scroll }]"
      >
        <slot name="body-content" />
      </div>
      <div
        ref="modalFooterElement"
        v-if="$slots.footer"
        class="modal-footer"
      >
        <slot name="footer" />
      </div>
    </div>
  </div>
  <DimmingOverlay
    v-if="props.open && props.showOverlay"
    :variant="DimmingOverlayVariant.standard"
    @close="close()"
  />
</template>

<style lang="scss" scoped>
@use './Modal.scss';
</style>
